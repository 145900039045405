
// var $ = require("jquery");
// window.jQuery = $;  //<-- This is what do the magic!!
// require("@fancyapps/fancybox");


import { newsItem } from './components/news-item.js'; //news item vue template
import { homeNewsLists, newsAndPublicationsList } from './components/news-lists.js';
import { loadYoutubeAsync, createYTVideos, heroLocalVideo } from './components/hero-video.js';
import { desktopMenu, resizeWindowMenu, googleTranslator } from './components/desktop-menu.js';
import { meetingArchive } from './components/meeting-archive.js';
import { multimediaGallery } from './components/multimedia-gallery.js';
import { consentBanner, alertBanner } from './components/cookie-banner';
import { initMobileMenu } from './components/mobile-menu';
import { searchResults } from './components/search-results';

import { Events } from './components/events';

import SitecoreExpEditorPlugin from 'vue-scexpeditor';


$(document).ready(function(){
    console.log('document ready!');
    Vue.use(SitecoreExpEditorPlugin);
    if(typeof Sitecore !== 'undefined') {
        document.querySelectorAll('code[type="text/sitecore"]').forEach(el => el.setAttribute('v-pre', ''));
        Vue.use(SitecoreExpEditorPlugin);
        // eslint-disable-next-line no-undef
        Sitecore.PageModes.ChromeManager.resetChromes();
    }
    //desktop menu
    desktopMenu();

    //meeting archive
    meetingArchive();

    //multimedia gallery
    multimediaGallery();

    //news lists
    homeNewsLists();
    newsAndPublicationsList();

    //home hero videos
    if( $('.youtube-video').length != 0 ){
        loadYoutubeAsync();
        createYTVideos();
    }
    heroLocalVideo();

    //cookies for alert banners
    consentBanner();
    alertBanner();

    // Mobile menu
    initMobileMenu();

    //Google Translation
    googleTranslator();
    //triggerHtmlEvent();

    // events
    if( $('#meetingAndEvents').length !=0 ){
        var service = $('#meetingAndEvents').attr('data-service');
        var dataParameters = {};  
        $.get(service, dataParameters, function(data, status, xhr) {             
            const events = new Events(data.EventsResponse);
            events.init();       
        })           
        .fail(function(jqxhr, settings, ex) {  
            $('#meetingAndEvents').append('<p>Service Error '+ ex +'</p>');
        })
        .always(function () {
            //console.log('Request finished');    
            $('#meetingAndEvents .loader-container').hide(); 
        });
    }
    
    // search results
    if($("#searchResults").length !=0 ){
        searchResults();
    }

    // Show dark overlay on resize
    $(window).resize(function () {
        resizeWindowMenu();
    });

    // offset alert when visible
    function isHidden(el) {
        if (el.offsetParent === null) {
            return true;
        }
        return false;
      }
      
      // alert banner height
      var alert = document.getElementById('close-alert');
      var dh = $('#cookies-alert').height();
        if(alert && !isHidden(alert)){
            document.body.style.marginTop = (alert.offsetHeight + dh+0) + 'px';
        }
        if(alert) {
        alert.addEventListener('click',function(){
            document.body.style.marginTop = '0';
        });
      }
});

//mobile search menu
$(document).click(function (e) {
    if (!$("#searchMobile").is(e.target) && $("#searchMobile").has(e.target).length == 0) {
        $("#search-dropdown").prop('checked', false);
    }
});

// html forms set the action
// document.getElementById('htmlForm').setAttribute('action', 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8');

const form = document.querySelector("#htmlForm");

async function sendData() {
  // Associate the FormData object with the form element
  const formData = new FormData(form);

  try {
    const response = await fetch("https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8", {
      method: "POST",
      // Set the FormData instance as the request body
      body: formData,
    });
    console.log(await response.json());
  } catch (e) {
    console.error(e);
  }
}

// Take over form submission
form.addEventListener("submit", (event) => {
  event.preventDefault();
  sendData();
});